import kycApi from "../kyc-api";

const service = {
  /**
   * getSurveyList
   * Get a full list of surveys (may need to add condition with user's company in the future)
   * @returns {Object<Survey>[]}
   */
  getSurveyList: async () => {
    try {
      return await kycApi
        .get("/surveys")
        .then((data) => {
          data.forEach((element) => {
            element.groups.sort((a, b) => {
              console.log(a, b);
              return a.sequence - b.sequence;
            });
          });
          console.log(data);
          return data;
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  /**
   * getSurvey
   * @param {Object} requestBody
   * @param {string} [requestBody.surveyid]
   * @returns {Object<Question>[]}
   */
  getSurvey: async (requestBody) => {
    let params = { id: requestBody.surveyid, _limit: 1 };

    try {
      return await kycApi
        .get("/surveys", { params })
        .then((data) => {
          console.log(data);
          return data;
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  /**
   * postSurveyList
   * @param {Object} requestBody
   * @param {string} [requestBody.name]
   * @returns {Object<Survey>}
   */
  postSurvey: async (requestBody) => {
    try {
      return await kycApi
        .post("/surveys", requestBody)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  /**
   * updateSurvey
   * @param {Object} requestBody
   * @param {Number} [requestBody.id]
   * @param {Array} [requestBody.groups]
   * @param {string} [requestBody.name]
   * @returns {Object<Survey>}
   */
  updateSurvey: async (requestBody) => {
    const id = requestBody.id;
    let obj = { name: requestBody.name, groups: requestBody.groups };

    try {
      return await kycApi
        .put(`/surveys/${id}`, obj)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  /**
   * deleteSurvey
   * @param {Object} requestBody
   * @param {Number} [requestBody.id]
   * @returns {Object<Survey>}
   */
  deleteSurvey: async (requestBody) => {
    try {
      const id = requestBody.id;

      return await kycApi
        .delete(`/surveys/${id}`)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },
};

export default service;
